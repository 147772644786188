// extracted by mini-css-extract-plugin
export var bank = "index-module--bank--Pr1kK";
export var clickArea = "index-module--clickArea--yeYpZ";
export var content = "index-module--content--pX6Lh";
export var contentHover = "index-module--contentHover--We57S";
export var descBg = "index-module--descBg--LVTZE";
export var descContent = "index-module--descContent--qV9Et";
export var experience = "index-module--experience--U-aPX";
export var experienceKPI = "index-module--experienceKPI--dRKsI";
export var finger_matting = "index-module--finger_matting--XLnaJ";
export var guidance = "index-module--guidance--pJ4c9";
export var hoverShow = "index-module--hoverShow--I+J0j";
export var hoverUp = "index-module--hover-up--2msx+";
export var position_left = "index-module--position_left--5Pk-P";
export var viewData = "index-module--viewData---1Ilw";
export var watchVideo = "index-module--watchVideo--ckkLN";